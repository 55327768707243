import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import backArrow from "../../assets/arrow.svg"
import buttomLine from "../../assets/line.svg"
import {useForgotPasswordPostMutation} from "../../Services/ForgotPasswordApi"
import { toast } from "react-toastify";

const ForgotPassword = () => {
    const [forgotPass] = useForgotPasswordPostMutation();
    const navigate = useNavigate();
    const [email, setEmail] = useState('')


    const resetPasswordHandler = (e) => {
        e.preventDefault();
        let userData = {
            email:email
        }
        forgotPass(userData)
                .unwrap()
                .then((res) => {
                    localStorage.setItem("email", res?.data?.email);
                    toast.success(res?.message);
                    if (res?.success) {
                        navigate("/forget-verification");
                    }
                })
                .catch((err) => {
                    toast.error(err?.data?.message);
                });
    }

    return (
        <div className="section">
            <div className="height-100">
                <div className="top">
                    <div className="container-fluid px-md-100 mt-5">
                        <div className="row">
                            <div className="col-md-12">
                                <Link to={'/login'}><img src={backArrow} alt="arrow" className="img-fluid" /></Link>
                                <h4 className="design-color fw-bold mt-4">Forgot Password?</h4>
                                <p className="mt-3 text-muted fs-5 mb-0">We’ll send you a verification code</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="middle">
                    <div className="container-fluid px-md-100 mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                <form action="" className="form-login">
                                    <div className="row justify-content-center mt-3">
                                        <div className="col-md-12">
                                            <div className="mb-4">
                                                <input type="email"
                                                    className="form-control py-3"
                                                    placeholder="Email Address"
                                                    aria-label="email" aria-describedby="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <button type="submit"
                                        onClick={resetPasswordHandler}
                                        className="btn bg-button w-100 rounded-pill text-white py-3 mt-md-5 mt-lg-3 mt-xl-5 mt-3">Reset Password</button>
                                </form>

                                <h5 className="mt-5 fw-normal">
                                    <span className="text-black">Don’t have an account?</span>
                                    <Link to={'/register'} className="design-color">Sign up</Link>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom">
                    <div className="container-fluid px-md-100">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <img src={buttomLine} alt="line" className="line img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword