import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import editProduct from "../../../assets/edit-product.svg"
import deleteProduct from "../../../assets/delete.svg"
import DeleteProduct from '../deleteProduct/DeleteProduct'
import { GetUrl } from '../../../config/GetUrl'
import { useDeleteProductMutation,useProductActiveUnactiveMutation } from '../../../Services/ProductApi'
import LoadingAction from '../../../components/loader/LoadingAction'
import { setProductID } from "../../../Slices/productSlice"
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const AllProduct = (props) => {
    const { productData,onDeleteProductHandler } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false)
    const [deleteProducts] = useDeleteProductMutation()
    const [productActiveUnActive] = useProductActiveUnactiveMutation()
    const [productId, setProductId] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [switchStates, setSwitchStates] = useState({});
    useEffect(() => {
        const initialSwitchStates = {};
        productData.forEach(product => {
            initialSwitchStates[product._id] = product.status === 'active'; // Set default value based on product status
        });
        setSwitchStates(initialSwitchStates);
    }, [productData]);


    const editHandler = (e, id) => {
        e.preventDefault()
        navigate('/edit-product')
        setProductId(id)
        dispatch(setProductID(id))
    }

    const deleteProductHandler = (e, id) => {
        e.preventDefault()
        setShow(true)
        setProductId(id)
    }
    const handleClose = () => {
        setShow(false)
    }

    const handleDeleteProduct = () => {
        setShowLoader(true)
        deleteProducts(productId)
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
                setShowLoader(false)
                onDeleteProductHandler(res)
            })
            .catch((err) => {
                setShowLoader(false)
                toast.error(err?.data?.message);
            });
    }
    const handleSwitchChange = (id) => {
        const newSwitchStates = { ...switchStates, [id]: !switchStates[id] };
        setSwitchStates(newSwitchStates);
        // Update product status based on switch change
        updateProductStatus(id, newSwitchStates[id]);
    };

    const updateProductStatus = (id, status) => {
        const updatedProductData = { productId:id, status: status ? 'active' : 'inactive' };
        productActiveUnActive(updatedProductData)
            .unwrap()
            .then((res) => {
                toast.success(res?.message);
            })
            .catch((err) => {
                toast.error(err?.data?.message);
            });
    };


    return (
        <div className="col-md-12 mt-4">
            {show && (<DeleteProduct show={show} handleClose={handleClose} handleDelete={handleDeleteProduct} />)}

            {showLoader && <LoadingAction />}
            {
                productData?.map(product => (
                    <div className="card tab-card mb-4">
                        <div className="card-body px-4">
                            <div className="row">
                                <div className="col-md-2 col-4">
                                    <img src={GetUrl?.IMAGE_URL + product?.images} alt="product image" className="img-fluid image_size_fixed" />
                                </div>

                                <div className="col-md-7 col-8">
                                    <div className="d-flex justify-content-between flex-column h-100">
                                        <h5 className="fw-bold text-black mb-0">{product.productName}</h5>
                                        <p className=" my-lg-0 my-2">{product.description}</p>
                                        <h3 className="fw-bolder design-color mb-md-0 mb-2">${product.itemPrice}</h3>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="align-items-md-end d-flex justify-content-between flex-md-column h-100">
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id={`flexSwitchCheckChecked-${product._id}`}
                                                checked={switchStates[product._id] || false}
                                                onChange={() => handleSwitchChange(product._id)}
                                            />

                                        </div>
                                        <div className="d-flex">
                                            <div className="me-3" onClick={(e) => editHandler(e, product?._id)}>
                                                <img src={editProduct} alt="edit-product" className="img-fluid" />
                                            </div>
                                            <div onClick={(e) => deleteProductHandler(e, product?._id)}>
                                                <img src={deleteProduct} alt="delete" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                ))
            }

        </div>
    )
}

export default AllProduct