import React,{useState} from 'react'
import Navbar from '../ButtomNav/Navbar';
import notificationIcon from "../../assets/notification-icon.svg"
import AccountInformation from './accountInformation/AccountInformation';
import Legal from './legal/Legal';
import Support from './support/Support';

const Settings = () => {
    const [usrname,setUsername] = useState(localStorage.getItem('fullName'))
    return (
        <div className="section">
            <div className="height-100">
                <div className="top">
                    <div className="container-fluid px-md-100 mt-5">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <h4 className="text-black fw-bold mb-0">Settings</h4>
                            </div>
                            <div className="col-6">
                                <a href="" className="float-end">
                                    <img src={notificationIcon} alt="notification" className="img-fluid noti-width" />
                                </a>
                            </div>

                            <div className="col-12">
                                <h3 className="design-color fw-bold mt-4">{usrname}</h3>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="middle">
                    <div className="container-fluid px-md-100 mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="mb-2 text-uppercase text-decoration-underline">Account Info</p>

                                <div className="list-group mt-4">
                                   
                                  <AccountInformation />
                                  <Legal />
                                <Support />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Navbar />
            </div>
        </div>
    )
}

export default Settings