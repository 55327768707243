import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Slide } from "react-toastify";

const initialState = {
 fullName: "",
 password:"",
 mobileNumber: "",
 numberOfProduct: "",
 address:"",
 zipCode:"",
 deliveryStaff:"",
};



export const createProfileSlice = createSlice({
  name: "createProfile",
  initialState,
  reducers: {
    // addEmail: (state, action) => {
    //   state.initialState.email= action.payload;
    // },
    setFullName(state, action) {
      state.fullName = action.payload;
    },
    setPassword(state, action) {
      state.fullName = action.payload;
    },
    setMobileNumber(state, action) {
      state.mobileNumber = action.payload;
    },
    setNumberOfProduct(state, action) {
      state.numberOfProduct = action.payload;
    },
    setAddress(state, action) {
      state.address = action.payload;
    },
    setZipCode(state, action) {
      state.zipCode = action.payload;
    },
    setDeliveryStaff(state, action) {
      state.deliveryStaff = action.payload;
    },
  },
});


// Action creators are generated for each case reducer function
export const {
  setFullName,
  setMobileNumber,
  setNumberOfProduct,
  setAddress,
  setZipCode,
  setDeliveryStaff,
  setPassword,
} = createProfileSlice.actions;

export default createProfileSlice.reducer;