// src/firebase-config.js
import { initializeApp } from '@firebase/app';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';
import { toastNotification, sendNativeNotification } from './notificationHelpers';
import { incrementNotificationCount } from './Slices/notificationSlice';
import { store } from './store';
// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAC6xg84dBVGWFu7kKK69-JV7tcFGF_N40",
  authDomain: "dealshaq-project-ae28a.firebaseapp.com",
  projectId: "dealshaq-project-ae28a",
  storageBucket: "dealshaq-project-ae28a.appspot.com",
  messagingSenderId: "470599093403",
  appId: "1:470599093403:web:fd3319654a7136eae48a3e",
  measurementId: "G-PGWBDTGXRT"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const setupNotifications = async () => {
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission();
    
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      // Get the FCM token
      const token = await getToken(messaging);
      console.log('FCM Token:', token);
    } else {
      console.log('Notification permission denied.');
    }
    // Handle foreground notifications
    onMessage(messaging, (payload) => {
      console.log('Foreground Message:', payload);
      const { title, body } = payload.notification;

      // Show a notification
      if (Notification.permission === 'granted') {
        new Notification(title, { body });
      }

      // Use custom notification functions
      toastNotification({ title, description: body, status: 'info' });
      sendNativeNotification({ title, body });
      store.dispatch(incrementNotificationCount());
    });
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};
export { messaging, setupNotifications };