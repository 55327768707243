import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import backArrow from "../../assets/arrow.svg"
import passwordShow from "../../assets/password-show.svg"
import showPassIcon from "../../assets/password-show-icon.svg"
import buttomLine from "../../assets/line.svg";
import { useRegisterPostMutation } from "../../Services/SignUpApi"
import { FormValidation, FormValidationOnChange } from "./FormValidation";
import { toast } from "react-toastify";
import LoadingAction from "../loader/LoadingAction"

const Register = () => {
    const [addSignupFormData] = useRegisterPostMutation();
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const [inputField, setInputField] = useState({
        email: "",
        password: "",
        role: "retailer"
    });
    const [errField, setErrField] = useState({
        emailErr: "",
        passwordErr: "",
    });

    const submitHandler = (e) => {
        e.preventDefault();
       // setShowLoader(true)
        if (FormValidation({ inputField, setErrField })) {
            localStorage.setItem("password", inputField.password);
            setShowLoader(true)
            addSignupFormData(inputField)
            .unwrap()
                .then((res) => { 
                    localStorage.setItem("email", res?.data?.email);
                    toast.success(res?.message);
                    setShowLoader(false);
                    if (res?.success) {
                        navigate("/verification");
                        setInputField({
                            email: "",
                            password: "",
                            role: ""
                        });  
                    }
                })
                .catch((error) => {
                    setShowLoader(false);
                    toast.error(error?.data?.message);
                });
        }
    }
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const inputChangeHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
        let inputName = e.target.name
        let inputValue = e?.target?.value
        FormValidationOnChange({ inputValue, setErrField, inputName })
    }

    console.log('showLoader--->',showLoader);

    return (
        <div className="section">
            <div className="height-100">
                <div className="top">
                    <div className="container-fluid px-md-100 mt-5">
                        <div className="row">
                            <div className="col-md-12">
                                <Link to={'/login'}><img src={backArrow} alt="arrow" className="img-fluid" /></Link>
                                <h4 className="design-color fw-bold mt-4">Create Account</h4>
                            </div>
                        </div>
                    </div>
                </div>
              {showLoader && <LoadingAction />}
                <div className="middle">
                    <div className="container-fluid px-md-100 mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                <form action="" className="form-login">
                                    <div className="mb-4">
                                        <input type="email"
                                            className="form-control py-3"
                                            placeholder="Email Address"
                                            aria-label="email"
                                            aria-describedby="email"
                                            name="email"
                                            value={inputField.email}
                                            onChange={inputChangeHandler}
                                        />
                                        {errField?.emailErr?.length > 0 && (
                                            <span className="error">{errField?.emailErr}</span>
                                        )}
                                    </div>

                                    <div className="position-relative">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="form-control py-3 position-relative"
                                            placeholder="Password" aria-label="password"
                                            aria-describedby="password"
                                            name="password"
                                            value={inputField.password}
                                            onChange={inputChangeHandler}
                                        />
                                        <img src={showPassword?showPassIcon:passwordShow} alt="password-show" className="img-fluid position-absolute password-show" onClick={togglePasswordVisibility} />
                                    </div>
                                    {errField?.passwordErr?.length > 0 && (
                                        <span className="error">{errField?.passwordErr}</span>
                                    )}

                                    <button type="submit"
                                        className="btn bg-button w-100 rounded-pill text-white py-3 mt-md-5 mt-lg-3 mt-xl-5 mt-3"
                                        onClick={submitHandler}
                                    >Continue</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom">
                    <div className="container-fluid px-md-100">
                        <div className="row">
                            <div className="col-md-12">
                                <h5 className="text-center mb-3">
                                    <span className="text-black">Already have an Account?</span>
                                    <Link to={'/login'} className="design-color fw-bold text-decoration-none">Log in</Link>
                                </h5>

                                <div className="text-center">
                                    <img src={buttomLine} alt="line" className="line img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register