import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import backArrow from "../../assets/arrow.svg";
import buttomLine from "../../assets/line.svg";
import passwordShow from "../../assets/password-show.svg";
import showPassIcon from "../../assets/password-show-icon.svg";
import { FormValidation, FormValidationOnChange } from "./FormValidation";
import { useLoginPostMutation } from "../../Services/LoginApi";
import { toast } from "react-toastify";
import { doLogin } from "../../auth/index";
import LoadingAction from "../loader/LoadingAction";
//import { messaging, getToken, onMessage  } from "../../firebase";


const Login = () => {
  const navigate = useNavigate();
  const [addLoginFormData] = useLoginPostMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [inputField, setInputField] = useState({
    email: "",
    password: "",
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [errField, setErrField] = useState({
    emailErr: "",
    passwordErr: "",
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };
  const submitHandler = (e) => {
    e.preventDefault();

    if (FormValidation({ inputField, setErrField })) {
      inputField["role"] = "retailer";
      setShowLoader(true);
      addLoginFormData(inputField)
        .unwrap()
        .then((res) => {
          setShowLoader(false);
          if (res?.success) {
            if (res.data.is_deactivated) {
              doLogin(res?.data?.token, res?.data?.fullName, res?.data?.email);
              toast.success(res?.message);
              navigate("/dashboard");
            } else {
              navigate("/login");
              toast.error("Your account has been deactivated.");
            }
          }
        })
        .catch((err) => {
          setShowLoader(false);
          toast.error(err?.data?.message);
        });
    }

    if (rememberMe) {
      document.cookie = `rememberMe=${inputField.email}:${inputField.password}; max-age=3600`;
    }
  };

  const inputChangeHandler = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
    let inputName = e.target.name;
    let inputValue = e?.target?.value;
    FormValidationOnChange({ inputValue, setErrField, inputName });
  };

  useEffect(() => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ");
    for (const cookie of cookies) {
      const [name, value] = cookie.split("=");
      if (name === "rememberMe") {
        const [savedUsername, savedPassword] = value.split(":");
        setInputField({
          email: savedUsername,
          password: savedPassword,
        });
        setRememberMe(true);
      }
    }
    console.log("Hello");
  }, []);


 



  return (
    <div className="section">
      <div className="height-100">
        <div className="top">
          <div className="container-fluid px-md-100 mt-5">
            <div className="row">
              <div className="col-md-12">
                <Link to={"/register"}>
                  <img src={backArrow} alt="arrow" className="img-fluid" />
                </Link>
                <h4 className="design-color fw-bold mt-4">Log in</h4>
              </div>
            </div>
          </div>
        </div>
        {showLoader && <LoadingAction />}
        <div className="middle">
          <div className="container-fluid px-md-100 mt-4">
            <div className="row">
              <div className="col-md-12">
                <form action="" className="form-login login">
                  <div className="mb-4">
                    <input
                      type="email"
                      className="form-control py-3"
                      placeholder="Email Address"
                      aria-label="email"
                      aria-describedby="email"
                      name="email"
                      value={inputField.email}
                      onChange={inputChangeHandler}
                    />
                    {errField?.emailErr?.length > 0 && (
                      <span className="error">{errField?.emailErr}</span>
                    )}
                  </div>

                  <div className="position-relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control py-3 position-relative"
                      placeholder="Password"
                      aria-label="password"
                      aria-describedby="password"
                      name="password"
                      value={inputField.password}
                      onChange={inputChangeHandler}
                    />
                    <img
                      src={showPassword ? showPassIcon : passwordShow}
                      alt="password-show"
                      className="img-fluid position-absolute password-show"
                      onClick={togglePasswordVisibility}
                    />
                  </div>
                  {errField?.passwordErr?.length > 0 && (
                    <span className="error">{errField?.passwordErr}</span>
                  )}

                  <div className="mb-3 mt-4 form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="rememberMe"
                      checked={rememberMe}
                      onChange={handleRememberMeChange}
                    />
                    <label
                      className="form-check-label text-black"
                      for="exampleCheck1"
                    >
                      Remember me
                    </label>
                  </div>

                  <button
                    type="submit"
                    className="btn bg-button w-100 rounded-pill text-white py-3 mt-md-5 mt-lg-3 mt-xl-5 mt-3"
                    onClick={submitHandler}
                  >
                    Log in
                  </button>
                </form>

                <h5 className="mt-5 fw-normal">
                  <span className="text-black">Forgot Password?</span>
                  <Link to={"/forget-password"} className="design-color">
                    Reset it
                  </Link>
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom">
          <div className="container-fluid px-md-100">
            <div className="row">
              <div className="col-md-12">
                <h5 className="text-center mb-3">
                  <span className="text-black">Don't have an Account?</span>
                  <Link
                    to={"/register"}
                    className="design-color fw-bold text-decoration-none"
                  >
                    Sign up
                  </Link>
                </h5>

                <div className="text-center">
                  <img src={buttomLine} alt="line" className="line img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
