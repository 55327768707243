import React from 'react';
import { Link } from 'react-router-dom'
import backArrow from "../../../../assets/arrow.svg"
import buttomLine from "../../../../assets/line.svg"

const AboutUs = () => {
  return (
    <div class="section">
            <div class="height-100">
                <div class="top">
                    <div class="container-fluid px-md-100 mt-5">
                        <div class="row">
                            <div class="col-md-12">   
                                <Link to={'/settings'}><img src={backArrow} alt="arrow" class="img-fluid" /></Link>
                                <h4 class="design-color fw-bold mt-4">About Us</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="middle">
                    <div class="container-fluid px-md-100 mt-4">
                        <div class="row">
                            <div class="col-md-12">
                                <p class="fs-5">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to 
                                make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, 
                                remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing 
                                Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including 
                                versions of Lorem Ipsum.</p>
                            
                            </div> 
                        </div>
                    </div>   
                    <div class="container-fluid px-md-100 mt-4">
                        <div class="row">
                            <div class="col-md-12">
                                <p class="fs-5">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                                standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to 
                                make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, 
                                remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing 
                                Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including 
                                versions of Lorem Ipsum.</p>
                            
                            </div> 
                        </div>
                    </div>  
                    <div className="container-fluid px-md-100 mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className='common-ul'>
                                    <li className="fs-5"> Lorem ipsum dolor sit amet, consectetur</li>
                                    <li className="fs-5">Adipiscing elit.</li>
                                    <li className="fs-5">Donec a elit quis dolor euismod dignissim.</li>
                                    <li className="fs-5">Nullam non odio condimentum, rhoncus nunc</li>
                                </ul>
                            </div>
                        </div>
                    </div>  
                </div>

                <div class="bottom">
                    <div class="container-fluid px-md-100">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="text-center">
                                    <img src={buttomLine} alt="line" class="line img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>  
            </div>
        </div>
  )
}

export default AboutUs