import React from 'react'
import { Link } from 'react-router-dom';
import userProfile from "../../../assets/user.svg";
import changePass from "../../../assets/change-password.svg";
import notifications from "../../../assets/notifications.svg";
import rightArrow from "../../../assets/arrow-right.svg"


const AccountInformation = () => {
    return (
        <>
            <Link to={'/view-user-profile'} className="border-0 p-0 mb-4 list-group-item d-flex justify-content-between align-items-center">
                <div className="me-auto">
                    <div className="h5 mb-0 d-flex align-items-end setting-images">
                        <img src={userProfile} alt="user" className="img-fluid me-3" /> User Profile
                    </div>
                </div>
                <span className="">
                    <img src={rightArrow} alt="arrowright" className="img-fluid arrow-right" />
                </span>
            </Link>

            <Link to={'/change-password'} className="border-0 p-0 mb-4 list-group-item d-flex justify-content-between align-items-center">
                <div className="me-auto">
                    <div className="h5 mb-0 d-flex align-items-end setting-images">
                        <img src={changePass} alt="change-password" className="img-fluid me-3" /> Change Password
                    </div>
                </div>
                <span className="">
                    <img src={rightArrow} alt="arrowright" className="img-fluid arrow-right" />
                </span>
            </Link>


            {/* <Link to={'/notifications'} className="border-0 p-0 mb-4 list-group-item d-flex justify-content-between align-items-center">
                <div className="me-auto">
                    <div className="h5 mb-0 d-flex align-items-end setting-images">
                        <img src={notifications} alt="notifications" className="img-fluid me-3" /> Notifications
                    </div>
                </div>
                <span className="">
                    <img src={rightArrow} alt="arrowright" className="img-fluid arrow-right" />
                </span>
            </Link> */}
        </>
    )
}

export default AccountInformation