import React from 'react'
import { Link } from 'react-router-dom'
import backArrow from "../../../../assets/arrow.svg"
import buttomLine from "../../../../assets/line.svg"



const Faq = () => {
  return (
    <div className="section">
            <div className="height-100">
                <div className="top">
                    <div className="container-fluid px-md-100 mt-5">
                        <div className="row">
                            <div className="col-md-12">   
                                <Link to={'/settings'}><img src={backArrow} alt="arrow" className="img-fluid" /></Link>
                                <h4 className="design-color fw-bold mt-4">Frequently Asked Questions</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="middle">
                    <div className="container-fluid px-md-100 mt-4">
                        <div className="row">
                            <div className="col-md-12">
                               <div className="accordion  accordion-flush" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                          <button className="accordion-button bg-none shadow-none px-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            1. What is Lorem Ipsum?
                                          </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                          <div className="accordion-body  pt-0">
                                                <p className="mb-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry
                                                    standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to 
                                                    make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, 
                                                    remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing 
                                                    Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including 
                                                    versions of Lorem Ipsum.
                                                </p>
                                          </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                          <button className="accordion-button bg-none shadow-none px-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                            2. What is Lorem Ipsum?
                                          </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                          <div className="accordion-body  pt-0">
                                                <p className="mb-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry
                                                    standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to 
                                                    make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, 
                                                    remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing 
                                                    Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including 
                                                    versions of Lorem Ipsum.
                                                </p>
                                          </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>    
                    </div>
                </div>

                <div className="bottom">
                    <div className="container-fluid px-md-100">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <img src={buttomLine} alt="line" className="line img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>  
            </div>
        </div>
  )
}

export default Faq