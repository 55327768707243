import React from 'react';
import { Link } from 'react-router-dom'
import backArrow from "../../../../assets/arrow.svg"
import notificationIcon from "../../../../assets/notification-circle.svg"
import buttomLine from "../../../../assets/line.svg"

const Notifications = () => {
  return (
    <div class="section">
    <div class="height-100">
        <div class="top">
            <div class="container-fluid px-md-100 mt-5">
                <div class="row">
                    <div class="col-md-12">   
                        <Link to={'/settings'}><img src={backArrow} alt="arrow" class="img-fluid" /></Link>
                        <h4 class="design-color fw-bold mt-4">Notifications</h4>
                    </div>
                </div>
            </div>
        </div>

        <div class="middle">
            <div class="container-fluid px-md-100 mt-4">
                <div class="row">
                    <div class="col-md-12">
                      <h6 class="text-black fw-semibold text-uppercase">Today</h6>

                      <div class="d-flex justify-content-between mt-4 align-items-center border-bottom pb-3">
                          <div class="notify-image">
                              <img src={notificationIcon} alt="notification" class="img-fluid" />
                              Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                          </div>

                          <div class="">
                              <p class="design-color small mb-0">2 min</p>
                          </div>
                      </div>

                      <div class="d-flex justify-content-between mt-4 align-items-center  pb-3">
                          <div class="notify-image">
                              <img src={notificationIcon} alt="notification" class="img-fluid" />
                              Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                          </div>

                          <div class="">
                              <p class="design-color small mb-0">2 min</p>
                          </div>
                      </div>

                       <h6 class="text-black fw-semibold text-uppercase mt-4">yesterday</h6>

                      <div class="d-flex justify-content-between mt-4 align-items-center border-bottom pb-3">
                          <div class="notify-image">
                              <img src={notificationIcon} alt="notification" class="img-fluid" />
                              Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                          </div>

                          <div class="">
                              <p class="design-color small mb-0">2 min</p>
                          </div>
                      </div>

                      <div class="d-flex justify-content-between mt-4 align-items-center  pb-3">
                          <div class="notify-image">
                              <img src={notificationIcon} alt="notification" class="img-fluid" />
                              Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                          </div>

                          <div class="">
                              <p class="design-color small mb-0">2 min</p>
                          </div>
                      </div>
                    </div>
                </div>    
            </div>
        </div>

        <div class="bottom">
            <div class="container-fluid px-md-100">
                <div class="row">
                    <div class="col-md-12">
                        <div class="text-center">
                            <img src={buttomLine} alt="line" class="line img-fluid" />
                        </div>
                    </div>
                </div>
            </div> 
        </div>  
    </div>
</div>
  )
}

export default Notifications