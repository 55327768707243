import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import backArrow from "../../../../assets/arrow.svg";
import editIcon from "../../../../assets/edit.svg";
import buttomLine from "../../../../assets/line.svg";
import DeleteUser from "./DeleteUser";
import { useRetrieveProfileQuery } from "../../../../Services/UserProfileApi";

const ViewUserProfile = (props) => {
  const {dataPass}=props
  const { data: userData,refetch } = useRetrieveProfileQuery();
  const [userRetrive, setUserRetrive] = useState("");
  const [show, setShow] = useState(false);
  const deleteAccountHandler = () => {
    setShow(true);
  };
  useEffect(() => {
    setUserRetrive(userData);
  }, [userData]);
  const handleClose = () => {
    setShow(false);
  };
  useEffect(()=>{
   
    if(dataPass){
      refetch()
    }
  },[dataPass])
  return (
    <div className="section">
      <div className="height-100">
        <div className="top">
          <div className="container-fluid px-md-100 mt-5">
            <div className="row">
              <div className="col-md-12 mb-3">
                <Link to={"/settings"}>
                  <img src={backArrow} alt="arrow" className="img-fluid" />
                </Link>
                <h4 className="design-color fw-bold mt-4">User Profile</h4>
              </div>

              <div className="col-6">
                <h5 className="text-black mb-0">Personal Info</h5>
              </div>
              <div className="col-6">
                <Link to={"/edit-user-profile"} className="float-end">
                  <img
                    src={editIcon}
                    alt="edit"
                    className="img-fluid edit-width me-xl-3"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="middle">
          <div className="container-fluid px-md-100 mt-4">
            <div className="row">
              <div className="col-md-12">
                <div className="card bg-new border-0 mb-4">
                  <div className="card-body px-md-4">
                    <p className="mb-2">Name</p>
                    <h6>{userRetrive?.data?.fullName}</h6>
                  </div>
                </div>

                <div className="card bg-new border-0 mb-4">
                  <div className="card-body px-md-4">
                    <p className="mb-2">Email Address</p>
                    <h6>{userRetrive?.data?.email}</h6>
                  </div>
                </div>

                <div className="card bg-new border-0 mb-4">
                  <div className="card-body px-md-4">
                    <p className="mb-2">Mobile Number</p>
                    <h6>{userRetrive?.data?.mobileNumber}</h6>
                  </div>
                </div>

                <div className="card bg-new border-0 mb-4">
                  <div className="card-body px-md-4">
                    <p className="mb-2">No. of Products</p>
                    <h6>{userRetrive?.data?.numberOfProduct}</h6>
                  </div>
                </div>

                <div className="card bg-new border-0 mb-4">
                  <div className="card-body px-md-4">
                    <p className="mb-2">Address</p>
                    <h6>{userRetrive?.data?.address}</h6>
                  </div>
                </div>

                <div className="card bg-new border-0 mb-4">
                  <div className="card-body px-md-4">
                    <p className="mb-2">Zip Code</p>
                    <h6>{userRetrive?.data?.zipCode}</h6>
                  </div>
                </div>

                <div className="card bg-new border-0 mb-4">
                  <div className="card-body px-md-4">
                    <p className="mb-2">
                      Do you have your own delivery staff?{" "}
                    </p>
                    <h6>{userRetrive?.data?.deliveryStaff}</h6>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="mb-4">
                  <a
                    className="design-color h6 fw-bold delete_acc"
                    onClick={deleteAccountHandler}
                  >
                    Delete Account
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom">
          <div className="container-fluid px-md-100">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center">
                  <img src={buttomLine} alt="line" className="line img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteUser show={show} handleClose={handleClose} />
    </div>
  );
};

export default ViewUserProfile;
