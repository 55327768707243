import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import backArrow from "../../assets/arrow.svg"
import buttomLine from "../../assets/line.svg"
import passwordShow from "../../assets/password-show.svg"
import passwordShowIcon from "../../assets/password-show-icon.svg"
import { FormValidation, FormValidationOnChange } from "./ResetPassValidation";
import { toast } from "react-toastify";
import {useCreateNewPasswordMutation} from "../../Services/ForgotPasswordApi"

const ResetPassword = () => {
    const [createNewPass] = useCreateNewPasswordMutation()
    const navigate = useNavigate();
    const [inputField, setInputField] = useState({
        password: "",
        confirmPassword: ''
    });
    const [errField, setErrField] = useState({
        passwordErr: "",
        confirmPasswordErr: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };




    const createNewPasswordHadler = (e) => {
        e.preventDefault();

        if (FormValidation({ inputField, setErrField })) {
            if (inputField?.password.trim() !== inputField.confirmPassword.trim()) {
                return toast.error("Password and confirmed password do not match")
            }
            let user = localStorage.getItem('email')
                const inputData = {
                    email:user,
                    password:inputField?.password
                }
            createNewPass(inputData)
                .unwrap()
                .then((res) => {
                    toast.success(res?.message);
                    if (res?.success) {
                        navigate("/login");
                    }
                })
                .catch((err) => {
                    toast.error(err?.data?.message);
                });
            

        }
        //navigate('/login')
    }

    const inputChangeHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
         let inputName = e.target.name
        let inputValue = e?.target?.value
         FormValidationOnChange({ inputValue, setErrField, inputName })
    }

    return (
        <div className="section">
            <div className="height-100">
                <div className="top">
                    <div className="container-fluid px-md-100 mt-5">
                        <div className="row">
                            <div className="col-md-12">
                                <Link to={'/forget-verification'}><img src={backArrow} alt="arrow" className="img-fluid" /></Link>
                                <h4 className="design-color fw-bold mt-4">Reset your Password.</h4>
                                <p className="mt-3 text-muted fs-5 mb-0">Your new password must be different from previously used passwords.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="middle">
                    <div className="container-fluid px-md-100 mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                <form action="" className="form-login">
                                    <div className="row justify-content-center mt-3">
                                        <div className="col-md-12">
                                            <div className="position-relative mb-4">
                                                <input type={showPassword ? "text" : "password"}
                                                    className="form-control py-3 position-relative"
                                                    placeholder="Password" aria-label="password"
                                                    aria-describedby="password"
                                                    name="password"
                                                    value={inputField?.password}
                                                    onChange={inputChangeHandler}
                                                />
                                                <img
                                                    src={showPassword?passwordShowIcon:passwordShow}
                                                    alt="password-show"
                                                    className="img-fluid position-absolute password-show" onClick={togglePasswordVisibility} />
                                                {errField?.passwordErr?.length > 0 && (
                                                    <span className="error">{errField?.passwordErr}</span>
                                                )}
                                            </div>



                                            <div className="position-relative">
                                                <input
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    className="form-control py-3 position-relative"
                                                    placeholder="Confirm Password"
                                                    aria-label="confirmpassword"
                                                    aria-describedby="confirmpassword"
                                                    name="confirmPassword"
                                                    value={inputField?.confirmPassword}
                                                    onChange={inputChangeHandler}
                                                />
                                                <img src={showConfirmPassword?passwordShowIcon:passwordShow}
                                                    alt="password-show"
                                                    className="img-fluid position-absolute password-show" onClick={toggleConfirmPasswordVisibility} />
                                            </div>
                                            {errField?.confirmPasswordErr?.length > 0 && (
                                                <span className="error">{errField?.confirmPasswordErr}</span>
                                            )}
                                        </div>
                                    </div>


                                    <button type="submit" onClick={createNewPasswordHadler} className="btn bg-button w-100 rounded-pill text-white py-3 mt-md-5 mt-lg-3 mt-xl-5 mt-3">Create New Password</button>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom">
                    <div className="container-fluid px-md-100">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <img src={buttomLine} alt="line" className="line img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword