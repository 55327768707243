import React from 'react';
import { Link } from 'react-router-dom';
import faqIcon from "../../../assets/faq.svg";
import about from "../../../assets/about.svg";
import privacy from "../../../assets/privacy.svg";
import terms from "../../../assets/terms.svg";
import rightArrow from "../../../assets/arrow-right.svg"

const Legal = () => {
    return (
        <>
            <p className="my-3 text-uppercase text-decoration-underline">Legal</p>

            <Link to={'/faq'} className="border-0 p-0 mt-2 mb-4 list-group-item d-flex justify-content-between align-items-center">
                <div className="me-auto">
                    <div className="h5 mb-0 d-flex align-items-end setting-images">
                        <img src={faqIcon} alt="faq" className="img-fluid me-3" /> Frequently Asked Questions
                    </div>
                </div>
                <span className="">
                    <img src={rightArrow} alt="arrowright" className="img-fluid arrow-right" />
                </span>
            </Link>

            <Link to={'/about-us'} className="border-0 p-0 mb-4 list-group-item d-flex justify-content-between align-items-center">
                <div className="me-auto">
                    <div className="h5 mb-0 d-flex align-items-end setting-images">
                        <img src={about} alt="about" className="img-fluid me-3" /> About Us
                    </div>
                </div>
                <span className="">
                    <img src={rightArrow} alt="arrowright" className="img-fluid arrow-right" />
                </span>
            </Link>

            <Link to={'/privacy-policy'} className="border-0 p-0  mb-4 list-group-item d-flex justify-content-between align-items-center">
                <div className="me-auto">
                    <div className="h5 mb-0 d-flex align-items-end setting-images">
                        <img src={privacy} alt="privacy" className="img-fluid me-3" /> Privacy Policy
                    </div>
                </div>
                <span className="">
                    <img src={rightArrow} alt="arrowright" className="img-fluid arrow-right" />
                </span>
            </Link>

            <Link to={'/term-and-condition'} className="border-0 p-0  mb-4 list-group-item d-flex justify-content-between align-items-center">
                <div className="me-auto">
                    <div className="h5 mb-0 d-flex align-items-end setting-images">
                        <img src={terms} alt="terms" className="img-fluid me-3" /> Terms and Conditions
                    </div>
                </div>
                <span className="">
                    <img src={rightArrow} alt="arrowright" className="img-fluid arrow-right" />
                </span>
            </Link>
        </>
    )
}

export default Legal