import React, { useEffect, useState } from 'react'
import Navbar from '../ButtomNav/Navbar'
import PendingDelivery from './pendingDelivery/PendingDelivery'
import notificationIcon from "../../assets/notification-icon.svg"
import ConfirmedDelivery from './confirmedDelivery/ConfirmedDelivery'
import CompleteDelivered from './completeDelivered/CompleteDelivered'
import {useGetOrderStatusQuery} from "../../Services/OrderApi"

const MyMenu = () => {
const [activeSlider,setActiveSlider] = useState('Confirmed')
const [orderList,setOrderList] = useState([])
const { data } = useGetOrderStatusQuery({ status:activeSlider })

    const commonSlider=(data)=>{
console.log('data--->',data);
setActiveSlider(data)
    }
    useEffect(()=>{
        setOrderList(data?.data)
    },[data,activeSlider])
console.log('activeSlider-->',activeSlider);

  return (
    <div className="section">
    <div className="height-100">
        <div className="top">
            <div className="container-fluid px-md-100 mt-5">
                <div className="row align-items-center">
                    <div className="col-6">   
                        <h4 className="text-black fw-bold mb-0">My Orders</h4>
                    </div>
                    <div className="col-6">
                        <a href="" className="float-end">
                            <img src={notificationIcon} alt="notification" className="img-fluid noti-width" />
                        </a>
                    </div>

                    <div className="col-md-12">
                        <ul className="nav nav-pills mb-3 mt-4 nav-fill" id="menu-tab" role="tablist">
                          {/* <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pending-tab" data-bs-toggle="pill" data-bs-target="#pending" type="button" role="tab" aria-controls="pending" aria-selected="true">Pending</button>
                          </li> */}
                          <li className="nav-item me-md-5 me-2" role="presentation">
                            <button className="nav-link active" id="confirmed-tab" data-bs-toggle="pill" data-bs-target="#confirmed" type="button" role="tab" aria-controls="confirmed" aria-selected="false" onClick={()=>commonSlider('Confirmed')}>Confirmed</button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button className="nav-link" id="complete-tab" data-bs-toggle="pill" data-bs-target="#complete" type="button" role="tab" aria-controls="complete" aria-selected="false" onClick={()=>commonSlider('Completed')}>Complete</button>
                          </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>

        <div className="middle">
    <div className="container-fluid px-md-100 mt-4">
        <div className="row">
            <div className="col-md-12">

                <div className="tab-content" id="pills-tabContent">
                
                {/* <PendingDelivery /> */}
              {activeSlider==='Confirmed' && <ConfirmedDelivery  onDataHandlerforConfirm={orderList} activeSliderConfirmed={activeSlider}/>}  
             {activeSlider==='Completed' && <CompleteDelivered onDataCompletedHanlder={orderList} activeSliderCompleted={activeSlider}/>}
                </div>
            </div>
        </div>
    </div>
</div>
       

        <Navbar />
    </div>
</div>
  )
}

export default MyMenu