import React from 'react'
import { useNavigate } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import {doLogout} from "../../../auth/index"

const LogoutModal = (props) => {
    const { show, handleClose } = props;
    const navigate = useNavigate()
    const cancelHandler = () =>{
        handleClose();
    }
    const navigateHandler = () =>{
          navigate('/login');
     doLogout();
    }
  return (
    <Modal show={show} onHide={handleClose} centered>
    <Modal.Header
      closeButton={true}
      className="border-0 mt-4 pt-0 pb-2"
    ></Modal.Header>
    <Modal.Body className="text-center px-md-5">
      <div className="modal-body text-center">
        <h4 class="design-color fw-bold my-3 w-75 mx-auto">
          Are you sure you want to logout this account?
        </h4>
      </div>
      <div className="modal-footer d-block text-center border-0">
        <div>
          <button
            type="button"
            className="btn text-73"
            aria-label="Close"
            onClick={cancelHandler}
          >
            Cancel
          </button>
        </div>
        <hr />
        <div>
          <button
            type="button"
            className="btn text-black fw-bold "
            onClick={navigateHandler}
          >
            Ok
          </button>
        </div>
      </div>
    </Modal.Body>
  </Modal>
  )
}



export default LogoutModal