import React, { useState,useEffect } from "react";
import Navbar from "../ButtomNav/Navbar";
import notificationIcon from "../../assets/notification-icon.svg";
import LineChart from "./LineChart";
import WeeklyActivity from "./Chart/WeeklyActivity"
import PerHour from "./Chart/perHour/PerHour";
import PerDay from "./Chart/perDay/PerDay";
import PerWeek from "./Chart/perWeek/PerWeek";
import PerMonth from "./Chart/perMonth/PerMonth";

import { useSelector } from 'react-redux';

const Dashboard = () => {
  const [usrname, setUsername] = useState(localStorage.getItem("fullName"));
  const [graph,setGraph]=useState('perHour')
  const [notificationCount, setNotificationCount] = useState(1);
  const notificationCounts = useSelector((state) => state.notifications.notificationCount);


  console.log('notificationCounts=========>',notificationCounts)
  
  const orders = [
    {
      id: "#456432432",
      orderDateTime: "11 March 2024, 03:00 AM",
      deliveryDateTime: "12 March 2024, 7:00 PM - 8:00 PM",
    },
    {
      id: "#456432433",
      orderDateTime: "12 March 2024, 03:00 AM",
      deliveryDateTime: "13 March 2024, 7:00 PM - 8:00 PM",
    },
    {
      id: "#456432434",
      orderDateTime: "13 March 2024, 03:00 AM",
      deliveryDateTime: "14 March 2024, 7:00 PM - 8:00 PM",
    },
    // Add more objects for each order as needed
  ];

  const submitHandlerAllGraph = (e,graph)=>{
   e.preventDefault();
   setGraph(graph);
   
  }
 
  return (
    <div className="section">
      <div className="height-100">
        <div className="top">
          <div className="container-fluid px-md-100 mt-5">
            <div className="row align-items-center">
              <div className="col-9">
                <h4 className="text-black fw-bold mb-0">
                  Welcome,
                  <span className="design-color h5 fw-bold">{usrname}</span>
                </h4>
              </div>
              <div className="col-3">
              <a href="" className="float-end position-relative">
                  <img
                    src={notificationIcon}
                    alt="notification"
                    className="img-fluid noti-width"
                  />
                  {notificationCounts > 0 && (
                    <span className="badge bg-danger position-absolute top-0 start-100 translate-middle rounded-circle">
                      {notificationCounts}
                    </span>
                  )}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="middle">
          <div className="container-fluid px-md-100 mt-4">
            <div className="row">
              <div className="col-md-12">
                <p className="mb-2 text-uppercase text-decoration-underline">
                  Dashboard Overview
                </p>
              </div>
              <div className="col-md-12">
                <div className="mb-3 chart-main">
                  <LineChart />
                </div>
              </div>

              <div className="col-md-7">
                <p className="mb-2 text-uppercase text-decoration-underline">
                  Revenue
                </p>
                <div className="mb-3 chart-main-weekly">
                  <WeeklyActivity />
                </div>
              </div>

              <div className="col-md-5">

                <p className="mb-2 text-uppercase text-decoration-underline">
                  Order size
                </p>
                <ul className="nav nav-pills average-order-size mb-3" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" onClick={(e)=>submitHandlerAllGraph(e,'perHour')} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Per hour</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" onClick={(e)=>submitHandlerAllGraph(e,'perDay')} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Per day</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" onClick={(e)=>submitHandlerAllGraph(e,'perWeek')} id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Per week</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" onClick={(e)=>submitHandlerAllGraph(e,'perMonth')} id="pills-disabled-tab" data-bs-toggle="pill" data-bs-target="#pills-disabled" type="button" role="tab" aria-controls="pills-disabled" aria-selected="false">per month</button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">...</div>
                  <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">...</div>
                  <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">...</div>
                  <div class="tab-pane fade" id="pills-disabled" role="tabpanel" aria-labelledby="pills-disabled-tab" tabindex="0">...</div>
                </div>
                <div className="mb-3 chart-main-static">
                  {graph==='perHour' && (<PerHour />)}
                {graph==='perDay' && (<PerDay />)}
                {graph==='perWeek' && (<PerWeek />)}
                {graph==='perMonth' && (<PerMonth />)}
                </div>
              </div>

              {/* <div className="col-md-12">
                <p className="mb-2 text-uppercase text-decoration-underline">
                  Upcoming Orders
                </p>
                {orders.map((order, index) => (
                  <a href="" className="text-decoration-none" key={index}>
                    <div className="card tab-card mb-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6">
                            <p className="mb-1">Order ID</p>
                            <h6 className="design-color">{order.id}</h6>
                          </div>
                          <div className="col-6">
                            <div className="text-end">
                              <button className="btn btn-delivery">
                                Delivery
                              </button>
                            </div>
                          </div>
                          <div className="col-6 mt-2">
                            <p className="mb-1">Order Date, Time</p>
                            <h6 className="design-color">
                              {order.orderDateTime}
                            </h6>
                          </div>
                          <div className="col-6 mt-2">
                            <div className="float-end">
                              <p className="mb-1">Delivery Date, Time</p>
                              <h6 className="design-color">
                                {order.deliveryDateTime}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </div> */}
            </div>
          </div>
        </div>

        <Navbar />
      </div>
    </div>
  );
};

export default Dashboard;
