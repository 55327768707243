import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { GetUrl } from '../../config/GetUrl';
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const LineChart = () => {
  const [data, setData] = useState({
    labels: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Number of Confirmed Orders',
        data: [],
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        borderColor: 'red',
        fill: true,
        pointStyle: 'star',
        pointBorderColor: 'red',
        pointBackgroundColor: 'white',
      },
    ],
  });


  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = localStorage.getItem('token')
       // const response = await axios.get('https://dealshaq-api.alcax.com/api/retailer/get-orders-graph', {
        const response = await axios.get(GetUrl.API_URL+'/retailer/get-orders-graph', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const ordersCount = response.data;  // Adjust based on the actual response structure
        setData((prevData) => ({
          ...prevData,
          datasets: [
            {
              ...prevData.datasets[0],
              data: ordersCount,
            },
          ],
        }));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);



  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div style={{ height: '400px' }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
