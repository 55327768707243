import React, { useContext, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isLoggedIn } from "../auth";
import { AccountContext } from "./AccountContext"; // Import context

const Private = () => {
  const { isActive } = useContext(AccountContext);
  if (isActive === null) {
    return <div>Loading...</div>;
  }

  return isLoggedIn() && isActive ? <Outlet /> : <Navigate to="/login" />;
};

export default Private;
