import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

const SortingCategory = ({
  show,
  handleClose,
  selectedFilter,
  onDataChange,
}) => {
  const [inputField, setInputField] = useState(selectedFilter);

  useEffect(() => {
    setInputField(selectedFilter);
  }, [selectedFilter, show]);

  const closeHandler = () => {
    handleClose();
  };

  const submitHandler = () => {
    onDataChange(inputField);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="md">
      <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
        <div className="d-flex justify-content-center w-100">
          <Modal.Title className="h5 sort_by_color">Sort By</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="px-md-5">
        <div className="row">
          <div className="col-mb-4 col-lg-12">
            {[
              "Latest",
              "A-Z",
              "Z-A",
              "Price High to Low",
              "Price Low to High",
              "None",
            ].map((filter, index) => (
              <div key={index} className="mb-3 d-flex align-items-center">
                <div className="form-check dort_div">
                  <input
                    type="radio"
                    id={filter}
                    name="fav_language"
                    value={filter}
                    className="custom-radio form-check-input"
                    checked={inputField === filter}
                    onChange={(e) => setInputField(e.target.value)}
                  />
                  <label htmlFor={filter} className="mx-2 form-check-label">
                    {filter}
                  </label>
                  <br />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 mb-4 justify-content-center w-100">
        <div className="row justify-content-center w-100">
          <div className="row">
            <div className="col-6">
              <button
                type="button"
                className="btn bg-button reject w-100 rounded-pill py-2 fs-16"
                onClick={closeHandler}
              >
                Close
              </button>
            </div>
            <div className="col-6">
              <button
                type="button"
                onClick={submitHandler}
                className="btn bg-button w-100 rounded-pill text-white py-2 fs-16"
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SortingCategory;
