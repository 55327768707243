import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import backArrow from "../../assets/arrow.svg"
import buttomLine from "../../assets/line.svg";
import { toast } from "react-toastify";
import { useVerificationPostMutation, useResendOtpMutation } from "../../Services/VerificationApi";
import LoadingAction from '../loader/LoadingAction';

const Verification = () => {
  const [addVerificationCode] = useVerificationPostMutation();
  const [resendCode] = useResendOtpMutation();
  const navigate = useNavigate()
  const [inputs, setInputs] = useState(['', '', '', '']);
  const [showLoader, setShowLoader] = useState(false);

  const handleInputChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newInputs = [...inputs];
      newInputs[index] = value;
      setInputs(newInputs);

      if (index < 3 && value.length === 1) {
        document.getElementById(`input-${index + 1}`).focus();
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const verificationCode = inputs.join('');
    //navigate("/create-profile")
    let user = localStorage.getItem('email');
    let password = localStorage.getItem('password')

    if (verificationCode.length !== 4) {
      return toast.error("Please Enter verification code.");
    }
    let verificationDetail = {
      email: user,
      password:password,
      otp: verificationCode,
      role: "retailer"
    }
    setShowLoader(true)
    addVerificationCode(verificationDetail)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        setShowLoader(false)
        if (res?.success) {
          setInputs(['', '', '', ''])
          navigate("/create-profile");
        }
      })
      .catch((err) => {
        toast.error(err?.data?.message);
        setShowLoader(false)
      });

  };

  const resendOtpHandler = (e) => {
    e.preventDefault();
    let user = localStorage.getItem('email')
    let verificationDetail = {
      email: user,
    }
    resendCode(verificationDetail)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((err) => {
        toast.error(err?.data?.message);
      });

  }

  return (
    <div className="section">
      <div className="height-100">
        <div className="top">
          <div className="container-fluid px-md-100 mt-5">
            <div className="row">
              <div className="col-md-12">
                <Link to={'/register'}><img src={backArrow} alt="arrow" className="img-fluid" /></Link>
                <h4 className="design-color fw-bold mt-4">Verification Code</h4>
                <p className="mt-3 text-muted fs-5 mb-0">We’ll send you a verification code</p>
              </div>
            </div>
          </div>
        </div>
       {showLoader && <LoadingAction />}
        <div className="middle">
          <div className="container-fluid px-md-100 mt-4">
            <div className="row">
              <div className="col-md-12">
                <form onSubmit={handleSubmit} className="form-login otp">
                  <div className="row justify-content-center my-5">
                    <div className="col-md-9 col-lg-8">
                      <div className="d-flex justify-content-between">
                        {inputs.map((value, index) => (
                          <div key={index} className="input-width">
                            <input
                              type="number"
                              className="form-control py-3 text-center"
                              value={value}
                              onChange={(e) => handleInputChange(index, e.target.value)}
                              id={`input-${index}`}
                              maxLength={1}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <button type="submit" className="btn bg-button w-100 rounded-pill text-white py-3 mt-md-5 mt-lg-3 mt-xl-5 mt-3">Verify</button>
                </form>

                <h5 className="mt-5 fw-normal">
                  <span className="text-black">Didn’t receive OTP?</span>
                  <a className="design-color" onClick={resendOtpHandler}>Resend Code</a>
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom">
          <div className="container-fluid px-md-100">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center">
                  <img src={buttomLine} alt="line" className="line img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
