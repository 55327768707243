import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import { GetUrl } from '../../../../config/GetUrl';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const PerDay = () => {
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        borderColor: 'red',
        fill: true,
        pointStyle: 'star',
        pointBorderColor: 'red',
        pointBackgroundColor: 'white',
      },
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = localStorage.getItem('token')
        //const response = await axios.get('https://dealshaq-api.alcax.com/api/retailer/get-order-parDay-graph', {
          const response = await axios.get(GetUrl.API_URL+'/retailer/get-order-parDay-graph', {
        headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const labels = response.data.map(item => `${item.day}-${item.month}-${item.year}`);
        const datasetData = response.data.map(item => item.orderCount);
        
        setData({
          labels,
          datasets: [
            {
              data: datasetData,
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
              borderColor: 'red',
              fill: true,
              pointStyle: 'star',
              pointBorderColor: 'red',
              pointBackgroundColor: 'white',
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching the data', error);
      }
    };

    fetchData();
  }, []);

  const options = {
    plugins: {
        legend: {
          display: false,
        },           
      },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false, 
        },
      },
      y: {
        grid: {
          display: false, 
        },
      },
    },
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};

export default PerDay;
