import { configureStore } from "@reduxjs/toolkit";
import { signUpApi } from "./Services/SignUpApi";
import { verificationApi } from "./Services/VerificationApi";
import { createProfileApi } from "./Services/CreateProfileApi";
import { loginApi } from "./Services/LoginApi";
import { userProfileApi } from "./Services/UserProfileApi"
import { forgotPasswordApi } from "./Services/ForgotPasswordApi"
import createProfileSlice from "./Slices/createProfileSlice";
import productSlice from "./Slices/productSlice";
import { supportApi } from "./Services/SupportApi"
import { categoryApi } from "./Services/CategoryApi";
import { productApi } from "./Services/ProductApi";
import { orderApi } from "./Services/OrderApi";
//import { helpAndSupportApi } from "./Services/HelpAndSuppoerApi";
import notificationReducer from "./Slices/notificationSlice";


export const store = configureStore({
  reducer: {
    [signUpApi.reducerPath]: signUpApi.reducer,
    [verificationApi.reducerPath]: verificationApi.reducer,
    [createProfileApi.reducerPath]: createProfileApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [userProfileApi.reducerPath]: userProfileApi.reducer,
    [forgotPasswordApi.reducerPath]: forgotPasswordApi.reducer,
    [supportApi.reducerPath]: supportApi.reducer,
    createProfileSlice: createProfileSlice,
    productSlice:productSlice,
    notifications: notificationReducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      signUpApi.middleware,
      verificationApi.middleware,
      createProfileApi.middleware,
      loginApi.middleware,
      userProfileApi.middleware,
      forgotPasswordApi.middleware,
      supportApi.middleware,
      categoryApi.middleware,
      productApi.middleware,
      orderApi.middleware,

    ),
});
