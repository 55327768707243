import React, { useState } from 'react';
import { Link,useNavigate } from 'react-router-dom'
import backArrow from "../../../../assets/arrow.svg"
import buttomLine from "../../../../assets/line.svg"
import { FormValidation, FormValidationOnChange } from "./SupportValidation"
import { useSupportPostMutation } from "../../../../Services/SupportApi"
import { toast } from "react-toastify";

const HelpAndSupport = () => {
    const navigate = useNavigate();
    const [helpAndSupport] = useSupportPostMutation()
    const [inputField, setInputField] = useState({
        email: "",
        subject: "",
        description: ""

    });

    const [errField, setErrField] = useState({
        emailErr: "",
        subjectErr: "",
        descriptionErr: ""
    });


    const submitHandler = (e) => {
        e.preventDefault();

        if (FormValidation({ inputField, setErrField })) {
            helpAndSupport(inputField)
                .unwrap()
                .then((res) => {
                    toast.success(res?.message);
                    if (res?.success) {
                        navigate('/settings');
                    }
                })
                .catch((err) => {
                    toast.error(err?.data?.message);
                });
        }

    }


    const inputChangeHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
        let inputName = e.target.name
        let inputValue = e?.target?.value
        FormValidationOnChange({ inputValue, setErrField, inputName })
    }

    return (
        <div className="section">
            <div className="height-100">
                <div className="top">
                    <div className="container-fluid px-md-100 mt-5">
                        <div className="row">
                            <div className="col-md-12">
                                <Link to={'/settings'}><img src={backArrow} alt="arrow" className="img-fluid" /></Link>
                                <h4 className="design-color fw-bold mt-4">Help Support</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="middle">
                    <div className="container-fluid px-md-100 mt-4">
                        <div className="row">
                            <div className="col-md-12">
                                <form action="" className="form-login">
                                    <div className="mb-4">
                                        <input type="email"
                                            className="form-control py-3"
                                            placeholder="Email Address"
                                            aria-label="email"
                                            aria-describedby="email"
                                            name="email"
                                            value={inputField.email}
                                            onChange={inputChangeHandler}
                                        />
                                        {errField?.emailErr?.length > 0 && (
                                            <span className="error">{errField?.emailErr}</span>
                                        )}
                                    </div>

                                    <div className="mb-4">
                                        <input type="text"
                                            className="form-control py-3"
                                            placeholder="Subject"
                                            aria-label="subject"
                                            aria-describedby="subject"
                                            name="subject"
                                            value={inputField.subject}
                                            onChange={inputChangeHandler}
                                        />
                                        {errField?.subjectErr?.length > 0 && (
                                            <span className="error">{errField?.subjectErr}</span>
                                        )}
                                    </div>

                                    <div className="mb-4">
                                        <textarea id=""
                                            cols="30" rows="4"
                                            className="form-control"
                                            placeholder="Description"
                                            name="description"
                                            value={inputField.description}
                                            onChange={inputChangeHandler}
                                        ></textarea>
                                        {errField?.descriptionErr?.length > 0 && (
                                            <span className="error">{errField?.descriptionErr}</span>
                                        )}
                                    </div>


                                    <button type="button"
                                        onClick={submitHandler}
                                        className="btn bg-button w-100 rounded-pill text-white py-3 mt-md-5 mt-lg-3 mt-xl-5 mb-4 mt-3">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom">
                    <div className="container-fluid px-md-100">
                        <div className="row">
                            <div className="col-md-12">

                                <div className="text-center">
                                    <img src={buttomLine} alt="line" className="line img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HelpAndSupport