import React, { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import backArrow from "../../../../assets/arrow.svg";
import buttomLine from "../../../../assets/line.svg";
import {
  useRetrieveProfileQuery,
  useEditUserProfileMutation,
} from "../../../../Services/UserProfileApi";
import {FormValidation,FormValidationOnChange} from "./EditProfileValidation"
import { toast } from "react-toastify";

const EditUserProfile = (props) => {
  const {passData}=props
  const navigate = useNavigate();
  const { data: userRetrive } = useRetrieveProfileQuery();
  const [editUseProfile] = useEditUserProfileMutation();
  const [formData, setFormData] = useState({
    fullName: "",
    mobileNumber: "",
    numberOfProduct: "",
    address: "",
    zipCode: "",
    deliveryStaff: "",
  });
  const [errField, setErrField] = useState({
    fullNameErr: '',
    mobileNumberErr: '',
    numberOfProductsErr: '',
    addressErr: '',
    zipCodeErr: '',
});


  useEffect(() => {
    setFormData(userRetrive?.data);
  }, [userRetrive]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value;
    if (name === "zipCode") {
      inputValue = value.replace(/\D/g, "");
      if (inputValue.length > 6) {
        inputValue = inputValue.slice(0, 6);
      }
    }
    if (name === "mobileNumber") {
      inputValue = value.replace(/\D/g, ""); // Remove non-digit characters
      if (inputValue.length > 13) {
        inputValue = inputValue.slice(0, 13); // Trim to maximum 13 digits
      }
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));
     let inputName = name;
      FormValidationOnChange({ inputValue, setErrField, inputName });
  };

  const updateHandler = (e) => {
    e.preventDefault();
    if (FormValidation({ formData, setErrField })) {
      editUseProfile(formData)
      .unwrap()
      .then((res) => {
        localStorage.setItem("fullName", res?.data?.fullName);
        if (res?.success) {
          passData(res.success)
          navigate('/view-user-profile')
        }
      })
      .catch((err) => {
        console.log();
        toast.error(err?.data?.message);
      });
    }
    
  };




  return (
    <div className="section">
      <div className="height-100">
        <div className="top">
          <div className="container-fluid px-md-100 mt-5">
            <div className="row">
              <div className="col-md-12">
                <Link to={"/view-user-profile"}>
                  <img src={backArrow} alt="arrow" className="img-fluid" />
                </Link>
                <h4 className="design-color fw-bold mt-4">Edit Profile</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="middle">
          <div className="container-fluid px-md-100 mt-4">
            <div className="row">
              <div className="col-md-12">
                <form action="" className="form-login">
                  <div className="mb-4">
                    <input
                      type="text"
                      name="fullName"
                      value={formData?.fullName}
                      onChange={handleChange}
                      className="form-control py-3"
                      placeholder="Full Name"
                      aria-label="fullname"
                      aria-describedby="fullname"
                    />
                    {errField?.fullNameErr?.length > 0 && (
                                                <span className="error">{errField?.fullNameErr}</span>
                                            )}
                  </div>

                  <div className="mb-4">
                    <input
                      type="number"
                      name="mobileNumber"
                      value={formData?.mobileNumber}
                      onChange={handleChange}
                      className="form-control py-3"
                      placeholder="Mobile Number"
                      aria-label="mobilenumber"
                      aria-describedby="mobilenumber"
                    />
                    {errField?.mobileNumberErr?.length > 0 && (
                                                <span className="error">{errField?.mobileNumberErr}</span>
                                            )}
                  </div>

                  <div className="mb-4">
                    <input
                      type="number"
                      name="numberOfProduct"
                      value={formData?.numberOfProduct}
                      onChange={handleChange}
                      className="form-control py-3"
                      placeholder="Number of Products"
                      aria-label="productnumber"
                      aria-describedby="productnumber"
                    />
                    {errField?.numberOfProductsErr?.length > 0 && (
                                                <span className="error">{errField?.numberOfProductsErr}</span>
                                            )}
                  </div>

                  <div className="mb-4">
                    <textarea
                      name="address"
                      value={formData?.address}
                      onChange={handleChange}
                      id=""
                      cols="30"
                      rows="4"
                      className="form-control"
                      placeholder="Address"
                    ></textarea>
                    {errField?.addressErr?.length > 0 && (
                                                <span className="error">{errField?.addressErr}</span>
                                            )}
                  </div>

                  <div className="mb-4">
                    <input
                      type="number"
                      name="zipCode"
                      value={formData?.zipCode}
                      onChange={handleChange}
                      className="form-control py-3"
                      placeholder="Zip Code"
                      aria-label="zipcode"
                      aria-describedby="zipcode"
                    />
                    {errField?.zipCodeErr?.length > 0 && (
                                                <span className="error">{errField?.zipCodeErr}</span>
                                            )}
                  </div>

                  <div className="mb-4">
                    <label for="radio" className="form-label text-black fs-5">
                      Do you have your own delivery staff?
                    </label>

                    <div className="mt-2 deliverystaff">
                      <div className="form-check form-check-inline me-4">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="deliveryStaff"
                          id="yes"
                          value="Yes"
                          onChange={handleChange}
                        />
                        <label className="form-check-label" for="yes">
                          Yes
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="deliveryStaff"
                          id="inlineRadio2"
                          value="No"
                          onChange={handleChange}
                        />
                        <label className="form-check-label" for="inlineRadio2">
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={updateHandler}
                    className="btn bg-button w-100 rounded-pill text-white py-3 mt-md-5 mt-lg-3 mt-xl-5 mb-4 mt-3"
                  >
                    Update
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom">
          <div className="container-fluid px-md-100">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center">
                  <img src={buttomLine} alt="line" className="line img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserProfile;
