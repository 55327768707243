import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const PerHour = () => {
  const [data, setData] = useState({
    labels: ['1h', '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h'],
    datasets: [
      {
        data: [30, 26, 30, 42, 51, 42, 31, 59, 31,],
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        borderColor: 'red',
        fill: true,
        pointStyle: 'star',
        pointBorderColor: 'red',
        pointBackgroundColor: 'white',
      },
    ],
  });

  const options = {
    plugins: {
      legend: {
        display: false,
      },           
    },
    responsive: true, // Instruct chart js to respond nicely.
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false, 
        },
      },
      y: {
        grid: {
          display: false, 
        },
      },
    },
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};

export default PerHour